<template>
  <div class="analysis container">
    <div class="row">
      <div v-if="avgScore.reading_score" class="col-md-6">
        <score-card
          id="0"
          :user_score="avgScore"
          scoreDate="Students’ Average Score"
        ></score-card>
      </div>
    </div>
    <hr />
    <div class="total">
      <h4 class="title">Student's Detail Scores</h4>
      <el-collapse-transition>
        <div v-show="showScores">
          <table class="table table-border">
            <thead>
              <tr>
                <th>Student</th>
                <th>Score</th>
                <th>Super Score</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(student, index) in analysis.scores" :key="index">
                <td>
                  <a
                    :href="'/toefl/transcript?id=' + student.user_exam_id"
                    target="_blank"
                  >
                    {{ student.user_name }}
                  </a>
                </td>
                <td>
                  <toefl-score
                    :rscore="student.reading_score"
                    :lscore="student.listening_score"
                    :sscore="student.speaking_score"
                    :wscore="student.writing_score"
                    :tscore="student.total_score"
                    :rscore_up="0"
                    :gscore_up="0"
                    :escore_up="0"
                    :mscore_up="0"
                    :score_up="0"
                    :canEdit="false"
                    userExamId=""
                  ></toefl-score>
                </td>
                <td>
                  <b style="font-size:20px">{{ student.super_score }}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-collapse-transition>
      <div
        class="demo-block-control"
        style="left: 0px;"
        @click="showScores = !showScores"
      >
        <div v-show="!showScores">
          <i class="el-icon-caret-bottom"></i>
          <span style="">查看詳情</span>
        </div>
        <div v-show="showScores">
          <i class="el-icon-caret-top"></i>
          <span style="">收起</span>
        </div>
      </div>
    </div>
    <hr />
    <div class="total">
      <h4 class="title">
        Score Breakdown
        <el-button
          size="small"
          type="success"
          v-if="showPer"
          @click="showPer = false"
        >
          Show details
        </el-button>
        <el-button size="small" type="danger" v-else @click="showPer = true">
          Hide details
        </el-button>
      </h4>
      <div
        class="show-answer"
        v-for="(section, index) in sectionArr"
        :key="index"
      >
        <h4 v-html="section" style="margin-bottom:20px"></h4>
        <table id="table-correct" class="table" v-if="tests">
          <thead>
            <tr class="rnum_1">
              <th class="green-td">Num</th>
              <th class="green-td">Answer</th>
              <th class="green-td">正確率</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(answer, index) in tests[index]" :key="index">
              <td class="green-td">{{ index + 1 }}</td>
              <td>
                <span v-if="answer.answers.length > 0">
                  <router-link
                    target="_blank"
                    :to="{
                      name: 'ToeflResolve',
                      query: {
                        ...$route.query,
                        user_exam_id: 6156,
                        question_id: answer.id
                      }
                    }"
                  >
                    {{ answer.answers[0].options[0] }}
                  </router-link>
                  
                </span>
                <span v-else>
                  -
                </span>
              </td>
              <td>
                <div
                  v-if="answer !== undefined"
                  :class="
                    showAnswerCorrectPer(
                      answer.correct_count,
                      answer.exam_count
                    )
                  "
                >
                  <div v-if="showPer">
                    <b
                      >{{ getPer(answer.correct_count / answer.exam_count) }}%
                    </b>
                  </div>
                  <div v-else>
                    <el-tooltip
                      :content="showName(true, answer)"
                      placement="top"
                    >
                      <b class="text-success">
                        {{ answer.correct_count }}
                      </b>
                    </el-tooltip>
                    /
                    <b>
                      {{ answer.exam_count - answer.answer_count }}
                    </b>
                    /
                    <el-tooltip
                      :content="showName(false, answer)"
                      placement="top"
                    >
                      <b class="text-danger">
                        {{ answer.answer_count - answer.correct_count }}
                      </b>
                    </el-tooltip>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import ToeflScore from "@/views/sessionClasses/components/ToeflScore.vue";
import ScoreCard from "@/views/toefl/transcript/ScoreCard.vue";

export default {
  metaInfo() {
    return {
      title: "Analysis - Detail - Ivy-Way Academy"
    };
  },

  components: {
    ToeflScore,
    ScoreCard
  },

  mixins: [],

  props: ["tests", "analysis", "avgScore"],
  data() {
    return {
      testType: null,
      test: null,
      dateArr: null,
      teacherId: null,
      showScores: false,
      showPer: true,
      showAnswer: true,
      sectionArr: {
        reading: "Reading",
        listening: "Listening",
        speaking: "Speaking",
        writing: "Writing"
      }
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    showScore(total) {
      let score = "";
      score = Math.round(parseFloat(total));
      return score;
    },
    getPer(val) {
      var str = Number(val * 100).toFixed(2);
      return str;
    },
    showName(isCorrect, obj) {
      let students = [];
      if (isCorrect) {
        for (let key in obj.student_answers) {
          if (obj.answers[0].indexOf(key) > -1) {
            students = students.concat(obj.student_answers[key]);
          }
        }
      } else {
        for (let key in obj.student_answers) {
          if (obj.answers[0].indexOf(key) < 0) {
            students = students.concat(obj.student_answers[key]);
          }
        }
      }
      if (students.length > 0) {
        return students.join(", ");
      } else {
        return "None";
      }
    },
    showUp(now, last, section, count) {
      if (last === null) {
        return 0;
      } else {
        return (now[section] - last[section]) * count;
      }
    },
    showAnswerCorrectPer(correct, total) {
      let per = this.getPer(correct / total);
      if (parseFloat(per) < 50) {
        return "correct-style correct-0";
      } else if (parseFloat(per) >= 50 && parseInt(per) < 80) {
        return "correct-style correct-50";
      } else if (parseFloat(per) >= 80 && parseInt(per) < 100) {
        return "correct-style correct-80";
      } else if (parseInt(per) === 100) {
        return "correct-style correct-100";
      }
    }
  }
};
</script>

<style scoped>
hr {
  border-top: 2px solid #42a16a;
}
.analysis {
  padding: 20px;
}
.title {
  font-size: 30px;
}
.total p,
.total ul {
  margin: 0px;
}

.total {
  margin-top: 30px;
}
.total .title {
  font-size: 30px;
  color: #43a06b;
  margin-bottom: 20px;
}
.total_under {
  padding: 0px;
}
.total_above .score {
  font-size: 60px;
  color: #222;
  font-weight: 700;
  line-height: 70px;
  height: 70px;
}
.total .score + p {
  margin-top: 25px;
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  border-left: 4px solid #ccc;
}
.sat_left > div > span {
  display: inline-block;
  position: absolute;
  left: 420px;
  top: 28px;
}
.sat_left > div > p:first-child {
  font-size: 140px;
  font-weight: 700;
  color: #43a06b;
  line-height: 140px;
  margin-bottom: 0px;
}
.sat_right {
  padding-left: 0px;
  padding-top: 30px;
  border-bottom: 1px solid#DCDFE6;
}
.sat_right ul {
  clear: both;
}
.sat_right ul li {
  font-size: 20px;
  line-height: 30px;
}
.sat_right img {
  width: 90%;
}
.green-td {
  width: 96px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  background-color: #43a06b;
}
.table td,
.table th {
  height: 20px;
  padding: 2px 5px;
  text-align: center;
  vertical-align: middle;
  border: #4fb81e 2px solid;
}
.show-answer {
  width: 100%;
  overflow: auto;
}
.show-answer .table {
  position: relative;
  /* width: 100%; */
  display: block;
}
.show-answer thead {
  float: left;
}
.show-answer tbody tr {
  display: inline-block;
}
.show-answer th,
.show-answer td {
  font-weight: 500;
  height: 45px;
  line-height: 43px;
  display: block;
  border: #dee2e6 1px solid;
  padding: 0;
}
.show-answer th {
  width: 100px;
}
.show-answer td {
  width: 100px;
}
.show-answer td a {
  cursor: pointer;
}

.demo-block-control {
  /* border-top: 1px solid #eaeefb; */
  height: 44px;
  line-height: 44px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: center;
  margin-top: -1px;
  color: #4fb81e;
  cursor: pointer;
  position: relative;
}
.demo-block-control:hover {
  /* color: #409eff; */
  background-color: #f9fafc;
}
.correct-100 {
  background-color: #399460c2;
  color: white;
}
.correct-80 {
  color: #399460c2;
}
.correct-50 {
  color: #f56c6c;
}
.correct-0 {
  background-color: #f56c6c;
  color: white;
}
</style>

<template>
  <div class="score_border">
    <div class="score_border_head">
      <div>
        <h4>{{ scoreDate }}</h4>
        <h6>Test Date Scores</h6>
      </div>
    </div>
    <div class="row" style="padding: 0 20px">
      <div class="col-sm-4">
        <h5 class="text-center">Total Score</h5>
        <div>
          <div>
            <div class="text-center progress_total">
              <span class="total">{{ total }}</span>
              <el-button class="updateScore" type="text" @click="updateScore"
                ><i class="fas fa-cloud-upload-alt"></i
              ></el-button>
            </div>
          </div>
          <h5 class="text-center">out of 120</h5>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="row score_section rmax_score_progress">
          <div class="col-sm-5">
            <h5>
              Reading: <a href="">{{ user_score.reading_score }}</a> <br />
              <span class="maxScore_date"></span>
            </h5>
          </div>
          <div class="col-sm-7">
            <div style="height: 10px">
              <div
                class="corner"
                :style="{ 'margin-left': getLeft(user_score.reading_score) }"
              ></div>
            </div>
            <div style="height: 47px;line-height: 47px">
              <b style="display:inline-block;width: 10px;">0</b>
              <div class="score_progress"></div>
              <b>30</b>
            </div>
          </div>
        </div>
        <div class="row score_section lmax_score_progress">
          <div class="col-sm-5">
            <h5>
              Listening: <a href="">{{ user_score.listening_score }}</a>
              <!-- <br />
              <span class="maxScore_date">{{}}</span> -->
            </h5>
          </div>
          <div class="col-sm-7">
            <div style="height: 10px">
              <div
                class="corner"
                :style="{ 'margin-left': getLeft(user_score.listening_score) }"
              ></div>
            </div>
            <div style="height: 47px;line-height: 47px">
              <b style="display:inline-block;width: 10px;">0</b>
              <div class="score_progress"></div>
              <b>30</b>
            </div>
          </div>
        </div>

        <div class="score_section smax_score_progress">
          <div v-if="true" class="row">
            <div class="col-sm-5">
              <h5>
                Speaking: <a href="">{{ user_score.speaking_score }}</a>
                <!-- <br />
                <span class="maxScore_date">{{}}</span> -->
              </h5>
            </div>
            <div class="col-sm-7">
              <div style="height: 10px">
                <div
                  class="corner"
                  :style="{ 'margin-left': getLeft(user_score.speaking_score) }"
                ></div>
              </div>
              <div style="height: 47px;line-height: 47px">
                <b style="display:inline-block;width: 10px;">0</b>
                <div class="score_progress"></div>
                <b>30</b>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-sm-5">
              <h4>Speaking: -</h4>
            </div>
          </div>
        </div>

        <div class="score_section wmax_score_progress">
          <div v-if="true" class="row">
            <div class="col-sm-5">
              <h5>
                Writing: <a href="">{{ user_score.writing_score }}</a>
                <br />
                <!-- <span class="maxScore_date">{{}}</span> -->
              </h5>
            </div>
            <div class="col-sm-7">
              <div style="height: 10px">
                <div
                  class="corner"
                  :style="{ 'margin-left': getLeft(user_score.writing_score) }"
                ></div>
              </div>
              <div style="height: 47px;line-height: 47px">
                <b style="display:inline-block;width: 10px;">0</b>
                <div class="score_progress"></div>
                <b>30</b>
              </div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-sm-5">
              <h5>Writing: -</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import "@/views/toefl/transcript/style/reportcard.css";
import TOEFL from "@/apis/toefl";
export default {
  components: {},

  mixins: [],

  props: ["id", "user_score", "scoreDate"],
  data() {
    return {};
  },
  computed: {
    total() {
      let total = 0;
      total =
        this.user_score.reading_score +
        this.user_score.listening_score +
        this.user_score.speaking_score +
        this.user_score.writing_score;
      return total;
    }
  },
  watch: {},

  mounted() {},

  methods: {
    getLeft(score) {
      let progress_width = document.getElementsByClassName("score_progress")[0];
      let width = 140;
      if (progress_width != undefined) {
        width = progress_width.offsetWidth;
      }
      return (score / 30) * width + 8 + "px";
    },
    async updateScore() {
      await TOEFL.updateScore(this.id);
      this.$router.go(0);
    }
  }
};
</script>

<style scoped>
.score_progress {
  margin: 0 5px;
  width: 120px;
}
.score_border_head h4,
.score_border_head h6 {
  color: #fff;
}
h5 {
  font-size: 18px;
  line-height: 150%;
  margin: 15px 0;
  color: black;
}
.updateScore {
  display: none;
  font-size: 24px;
}
.progress_total:hover .updateScore {
  display: inline-block;
}

.progress_total:hover .total {
  display: none;
}
</style>

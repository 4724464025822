<template>
  <div>
    <table class="inner-table">
      <tbody>
        <tr>
          <td colspan="2">
            <el-tooltip effect="dark" content="Reading" placement="top">
              <b>
                {{ rscore }}
              </b>
            </el-tooltip>
            <b>
              <span
                class="asc-desc"
                style="color:#77ba41;"
                v-if="rscore_up > 0"
              >
                <i class="fa fa-sort-up" aria-hidden="true"></i>{{ rscore_up }}
              </span>
              <span class="asc-desc" style="color:#F00;" v-if="rscore_up < 0">
                <i class="fa fa-sort-down" aria-hidden="true"></i
                >{{ -rscore_up }}
              </span>
            </b>
          </td>
          <td colspan="2" class="M_score">
            <el-tooltip effect="dark" content="Listening" placement="top">
              <b>
                {{ lscore }}
              </b>
            </el-tooltip>
            <b>
              <span
                class="asc-desc"
                style="color:#77ba41;"
                v-if="lscore_up > 0"
              >
                <i class="fa fa-sort-up" aria-hidden="true"></i>{{ lscore_up }}
              </span>
              <span class="asc-desc" style="color:#F00;" v-if="lscore_up < 0">
                <i class="fa fa-sort-down" aria-hidden="true"></i
                >{{ -lscore_up }}
              </span>
            </b>
          </td>
          <td colspan="2" class="M_score">
            <el-tooltip effect="dark" content="Speaking" placement="top">
              <b>
                 {{ sscore }}
              </b>
            </el-tooltip>
            <b>
              <span
                class="asc-desc"
                style="color:#77ba41;"
                v-if="sscore_up > 0"
              >
                <i class="fa fa-sort-up" aria-hidden="true"></i>{{ sscore_up }}
              </span>
              <span class="asc-desc" style="color:#F00;" v-if="sscore_up < 0">
                <i class="fa fa-sort-down" aria-hidden="true"></i
                >{{ -sscore_up }}
              </span>
            </b>
          </td>
          <td colspan="2" class="M_score">
            <el-tooltip effect="dark" content="Writing" placement="top">
              <b>
                 {{ wscore }}
              </b>
            </el-tooltip>
            <b>
              <span
                class="asc-desc"
                style="color:#77ba41;"
                v-if="wscore_up > 0"
              >
                <i class="fa fa-sort-up" aria-hidden="true"></i>{{ wscore_up }}
              </span>
              <span class="asc-desc" style="color:#F00;" v-if="wscore_up < 0">
                <i class="fa fa-sort-down" aria-hidden="true"></i
                >{{ -wscore_up }}
              </span>
            </b>
          </td>
        </tr>
        <tr>
          <td colspan="8" class="T_score">
            <span v-if="canEdit">
              <el-tooltip effect="dark" content="Total" placement="top">
                <b>
                  <a
                    :href="'/sat/result?user_exam_id=' + userExamId"
                    target="_blank"
                  >
                    {{ tscore }}
                  </a>
                </b>
              </el-tooltip>
            </span>
            <span v-else>
              <el-tooltip
                v-if="classId > 0"
                effect="dark"
                :content="'Test' + week + ' Analysis'"
                placement="top"
              >
                <b>
                  <a
                    :href="
                      '/session-classes/analysis/detail?class_id=' +
                        classId +
                        '&week=' +
                        week
                    "
                    target="_blank"
                  >
                    {{ tscore }}
                  </a>
                </b>
              </el-tooltip>
              <b v-else>
                {{ tscore }}
              </b>
            </span>
            <b>
              <span class="asc-desc" style="color:#77ba41;" v-if="score_up > 0">
                <i class="fa fa-sort-up" aria-hidden="true"></i>{{ score_up }}
              </span>
              <span class="asc-desc" style="color:#F00;" v-if="score_up < 0">
                <i class="fa fa-sort-down" aria-hidden="true"></i
                >{{ -score_up }}
              </span>
            </b>
            &nbsp;&nbsp;
            <span
              v-if="canEdit"
              @click="setExam"
              class="score-edit"
              style="color:#77ba41;margin-left:10px"
              ><i class="fa fa-edit"></i
            ></span>
            <span
              v-if="canEdit"
              @click="removeExam"
              class="score-edit"
              style="color:#F00;margin-left:15px"
              ><i class="far fa-trash-alt"></i
            ></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: [
    "rscore",
    "lscore",
    "sscore",
    "wscore",
    "tscore",
    "rscore_up",
    "lscore_up",
    "sscore_up",
    "wscore_up",
    "score_up",
    "canEdit",
    "userExamId",
    "classId",
    "week"
  ],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    setExam() {
      this.$emit("setExam");
    },
    removeExam() {
      this.$emit("removeExam");
    }
  }
};
</script>

<style scoped>
.table td {
  padding: 5px;
  vertical-align: middle;
  text-align: center;
}
.inner-table {
  text-align: center;
  width: 100%;
  height: 50%;
  border: 1px #333;
}
table.inner-table tr th,
table.inner-table tr td {
  border: none;
}
.table td .M_score {
  border-left: 1px solid #e6e9ed;
}
.table-bordered th,
.table-bordered td {
  border-left: none;
}
.table td .T_score {
  border-top: 1px solid #e6e9ed;
}
.T_score b {
  font-size: 18px;
}
.R_score,
.G_score,
.E_score,
.M_score,
.M_score b,
.T_score b {
  color: #444;
}
.inner-table b {
  font-size: 18px;
}
.inner-table .T_score b {
  font-size: 20px;
}
.T_score {
  cursor: pointer;
}
.score-edit {
  display: none;
}
.T_score:hover .score-edit {
  cursor: pointer;
  display: inline-block;
}
</style>
